.toggle-with-text {
   display: flex;
   align-items: center;
   gap: 10px;
}

.toggle-with-text__container {
   cursor: pointer;
   background-color: #E8E8E8;
   border-radius: 17px;
   width: 36px;
   height: 18px;
   display: flex;
   align-items: center;
   padding: 3px;
}

.toggle-with-text__toggle {
   background: #ccc;
   width: 14px;
   height: 14px;
   border-radius: 50%;
}

.toggle-with-text__toggle_active {
   background-color: #00ACEF;
   margin-left: auto;
}

.toggle-with-text__toggle_inactive {
   background-color: #CCCCCC;
   margin-right: auto;
}

.toggle-with-text__input {
   display: none;
}

.toggle-with-text__text {
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 19px;
}