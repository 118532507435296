.filter-date-select {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.filter-date-select__label {
   font-size: 12px;
   line-height: 16.8px;
}

.filter-date-select__body {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.filter-date-select__value {
   min-height: 40px;
   padding: 8px 16px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   border-radius: 8px;
   background-color: white;
   font-size: 14px;
   line-height: 16.94px;
   color: #d8d3d3;
   cursor: pointer;
}

.filter-date-select__dropdown-icon {
   margin: 0;
   transform: rotate(180deg);
   transition: all 0.3s ease;
}

.filter-date-select.active .filter-date-select__dropdown-icon {
   transform: rotate(0deg);
}

.filter-date-select__list-container {
   padding: 8px 4px 8px 16px;
   border-radius: 8px;
   background-color: white;
   box-shadow: 0 32px 32px 0 rgba(28, 41, 61, 0.06);
}

.filter-date-select__list {
   max-height: 140px;
   display: flex;
   flex-direction: column;
   gap: 2px;
   list-style: none;
   overflow: auto;
}

.filter-date-select__list::-webkit-scrollbar {
   display: block;
   width: 4px;
   border-radius: 2px;
}

.filter-date-select__list::-webkit-scrollbar-thumb {
   border-radius: 2px;
   background-color: #40B7D9;
}

.filter-date-select__list-item {
   padding: 9px 0;
}

.filter-date-select-status {
   display: flex;
   align-items: center;
   gap: 8px;
   cursor: pointer;
}

.filter-date-select-status__checkbox {
   flex: 0 0 auto;
   width: 20px;
   height: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid rgba(232, 232, 232, 1);
   border-radius: 4px;
}

.filter-date-select-status_selected .filter-date-select-status__checkbox {
   border-color: rgba(64, 183, 217, 1);
   background-color: rgba(64, 183, 217, 1);
}

.filter-date-select-status__status {
   min-width: 97px;
   padding: 2px 8px;
   border-radius: 4px;
   background-color: rgba(83, 153, 235, 1);
   font-size: 12px;
   line-height: 16.8px;
   text-align: center;
   color: white;
}

.filter-date-select-status_overdue .filter-date-select-status__status {
   background-color: rgba(255, 204, 204, 1);
   color: rgba(237, 86, 86, 1);
}

.filter-date-select-status_today .filter-date-select-status__status {
   background-color: rgba(255, 227, 206, 1);
   color: rgba(245, 139, 63, 1);
}

.filter-date-select-status_future .filter-date-select-status__status {
   background-color: rgba(200, 255, 202, 1);
   color: rgba(67, 219, 74, 1);
}