.manager-edit-form-modal__footer {
   display: flex;
   gap: 16px;
}

.manager-edit-form-modal__btn {
   font-size: 16px;
   line-height: 19.36px;
}

.manager-edit-form-modal__btn_no {
   border: 1px solid #40B7D9;
   background-color: transparent;
   color: #40B7D9;
}