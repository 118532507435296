.chat-bot-settings-wrapper {
   padding: 15px 30px 10px 30px;
}

.create-chat-bot-form-control {
   display: grid;
   grid-template-columns: 150px 400px;
   align-items: center;
   margin-bottom: 10px;
}

.create-chat-bot-text-form {
   outline: none;
   width: 400px;
   border-radius: 4px;
   border: 1px solid #cccccc;
   box-sizing: border-box;
   height: 31px;
}

.add-chat-bot-word-group-list {
   width: 283px !important;
   border: none;
   outline: none;
   border: 1px solid #cccccc;
   border-radius: 4px;
   height: 38px;
   padding: 0 10px;
   margin-right: 10px;
}
