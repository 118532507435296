.mainbar {
   height: 100vh;
   position: relative;
   display: flex;
   flex-direction: column;
}

.mainbar-header {
   position: relative;
   z-index: 100;
   background: #f8f8f8;
}

.mainbar-header-wrapper {
   padding: 30px 30px 30px 30px;
}

.mainbar-header-conversation-control {
   display: flex;
   flex-direction: column;
   gap: 14px;
}

.mainbar-header-conversation-user-pic img {
   height: 42px;
   width: 42px;
   border-radius: 50%;
   object-fit: cover;
}

.mainbar-header-conversation-control__header {
   display: flex;
   align-items: center;
   gap: 10px;
}

.mainbar-header-conversation-username {
   font-weight: 800;
   font-size: 20px;
   line-height: 24px;
   overflow: hidden;
   text-overflow: ellipsis;
}

.mainbar-header-conversation-control__edit-name-btn {
   flex: 0 0 18px;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.mainbar-header-conversation-control__edit-name-btn svg {
   width: 16px;
   height: 16px;
}

.mainbar-header-conversation-control__edit-name-btn svg path {
   fill: rgba(0, 172, 239, 1);
}

.mainbar-header-conversation-control__edit-name-btn:hover svg path {
   fill: rgba(54, 194, 249, 1);
}

.mainbar-header-conversation-control__edit-name-btn:active svg path {
   fill: rgba(21, 167, 224, 1);
}

.mainbar-header-conversation-control__links {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.mainbar-header-conversation-control__links-item {
   align-self: flex-start;
   font-weight: 400;
   font-size: 14px;
   line-height: 16.94px;
   color: rgba(64, 183, 217, 1);
   text-decoration: underline;
}

.mainbar-header-conversation-control-buttons {
   display: flex;
   align-items: flex-end;
   gap: 20px;
}

.custom-select-control-body {
   position: relative;
   width: 100%; 
   display: flex;
   justify-content: space-between;
}

.custom-select-control-body__tooltip {
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 43px);
}

.custom-select-control-body:hover .custom-select-control-body__tooltip {
   visibility: visible;
   opacity: 1;
}

.mainbar-header-conversation-status__value-container {
   padding: 0 !important;
   margin: 0 !important;
   height: 24px !important;
   text-indent: 11px;
   width: 100% !important;
}

.mainbar-header-conversation-status-container {
   width: 170px;
   border: none;
   font-size: 12px;
   text-transform: lowercase;
   font-weight: 400;
   color: #fff;
}

.mainbar-header-conversation-status__control {
   border: none !important;
   outline: none !important;
   box-shadow: none !important;
   min-height: 24px !important;
   width: 178px !important;
   cursor: pointer !important;
   background: transparent !important;
   margin-right: 0px !important;
}

.mainbar-header-conversation-status__indicators {
   display: none !important;
}

.mainbar-header-conversation-status__menu {
   padding: 0 !important;
   margin: 0 !important;
   border: none !important;
   box-shadow: none !important;
   background: #ffffff !important;
}

.mainbar-header-conversation-status__option {
   border-radius: 10px;
   height: 23px;
   display: flex !important;
   align-items: center !important;
   margin-bottom: 3px;
   cursor: pointer;
}

.mainbar-header-conversation-status__option:nth-child(1) {
   background-color: #4bade9;
}

.mainbar-header-conversation-status__option:nth-child(2) {
   background-color: #9ccdeb;
}

.mainbar-header-conversation-status__option:nth-child(3) {
   background-color: #e17664;
}

.mainbar-header-conversation-status__option:nth-child(4) {
   background-color: #e2a095;
}

.mainbar-header-conversation-status__option:nth-child(5) {
   background-color: #42ae39;
}

.mainbar-header-conversation-status__option:nth-child(6) {
   background-color: #db4e45;
}

.mainbar-header-conversation-status__single-value {
   width: 200px !important;
   border-radius: 10px;
   height: 23px;
   display: flex !important;
   align-items: center !important;
   color: #fff;
   padding: 0 !important;
   margin: 0 !important;
}

.mainbar-header-conversation-channel-container {
   width: auto;
   border: 0.5px solid #000;
   font-size: 12px;
   text-transform: lowercase;
   font-weight: 400;
   color: #000;
   border-radius: 10px;
   background-color: #ffffff !important;
}

.mainbar-header-conversation-channel__control {
   border: none !important;
   outline: none !important;
   box-shadow: none !important;
   min-height: 24px !important;
   width: 130px !important;
   cursor: pointer !important;
   background-color: transparent !important;
}

.mainbar-header-conversation-channel__value-container {
   justify-content: center;
}

.mainbar-header-conversation-channel__indicators {
   display: none !important;
}

.mainbar-header-conversation-channel__menu {
   position: relative;
   left: 0;
}

.mainbar-header-add-tag-button {
   width: 110px;
   background-color: transparent;
   border: none;
   cursor: pointer;
   color: #a3a3a3;
   font-size: 12px;
}

.mainbar-conversations {
   height: calc(95vh - 155px);
   width: 100%;
   overflow-y: auto;
   padding: 20px 0;
   overflow-x: hidden;
   position: relative;
   display: flex;
   flex-direction: column-reverse;
}

.mainbar-conversations::-webkit-scrollbar {
   display: none;
}

.messages-list {
   display: flex;
   flex-direction: column-reverse;
}

.messages-loader {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   z-index: 5;
}

.messages-loader {
   background-color: rgba(255, 255, 255, 0.7);
}

.mainbar-conversation-input-message-card {
   width: 100%;
   margin: 0;
   white-space: pre-wrap;
   word-break: break-word;
   transition: background-color 0.3s ease;
}

.mainbar-conversation-output-message-card {
   transition: background-color 0.3s ease;
}

.mainbar-conversation-input-message-card,
.mainbar-conversation-output-message-card {
   padding: 5px 25px;
}

.mainbar-conversation-input-message-card img,
.mainbar-conversation-output-message-card img {
   -webkit-touch-callout: none !important;
}

.mainbar-conversation-input-message-card > .mainbar-conversation-message-card-wrapper {
   background: #e7e9f1;
   border-radius: 12px 12px 12px 0;
   float: left;
   color: #7f808c;
}

.mainbar-conversation-output-message-card > .mainbar-conversation-message-card-wrapper {
   background: rgba(64, 183, 217, 1);
   border-radius: 12px 12px 0 12px;
   float: right;
}

.mainbar-conversation-input-message-card > .mainbar-conversation-message-card-wrapper > .mainbar-conversation-message-card-text {
   position: relative;
}

.mainbar-conversation-message-card_disabled {
   cursor: pointer;
}

.mainbar-conversation-message-card-wrapper {
   font-size: 14px;
   padding: 18px;
   line-height: 21px;
   margin: 4px 0;
   overflow-wrap: break-word;
   color: #fff;
   position: relative;
   width: 60%;
   height: auto;
   padding-bottom: 34px;
}

.mainbar-conversation-message-card_disabled .mainbar-conversation-message-card-wrapper {
   pointer-events: none;
}

.mainbar-conversation-output-message-card {
   width: 100%;
   margin: 0;
   display: flex;
   flex-direction: column;
   align-items: flex-end;
   white-space: pre-wrap;
   word-break: break-word;
}

.mainbar-conversation-message-mailer-name {
   font-style: normal;
   font-weight: 400;
   font-size: 13px;
   line-height: 16px;
   color: #60858e;
}

.mainbar-conversation-message-mailer-name__status {
   display: none;
   color: #ED5656;
}

.mainbar-conversation-message-mailer-name__status_show {
   display: inline;
   color: #ED5656;
}

.mainbar-conversation-input-message-card .mainbar-conversation-message-mailer-name {
   margin-left: 12px;
}

.mainbar-conversation-output-message-card .mainbar-conversation-message-mailer-name {
   margin-right: 12px;
}

.mainbar-conversation-output-message-card-wrapper {
   background: #00acef;
   border-radius: 12px 12px 0px 12px;
   width: 60%;
   color: #fff;
   font-size: 14px;
   padding: 18px 18px 34px;
   line-height: 21px;
   margin: 4px 0;
   float: right;
   overflow-wrap: break-word;
   position: relative;
   height: 100%;
}

.mainbar-conversation-message-card-time {
   position: absolute;
   right: 26px;
   bottom: 12px;
   font-size: 12px;
   color: rgba(37, 37, 37, 0.7);
   line-height: 12px;
   display: flex;
   flex-direction: row-reverse;
   align-items: center;
   gap: 3px;
}

.mainbar-conversation-output-message-card .mainbar-conversation-message-card-time {
   color: rgba(174, 227, 242, 1);
}

.mainbar-conversation-error-desc {
   position: absolute;
   top: -25px;
   right: 5px;
   background-color: #000;
   color: #fff;
   padding: 3px 5px;
   border-radius: 5px;
   white-space: nowrap;
   width: auto;
   height: auto;
   cursor: pointer;
   overflow-wrap: break-word;
}

.mainbar-conversation-message-card_active {
   background-color: rgba(222, 248, 255, 1);
}

.mainbar-conversation-empty-space-card {
   width: 100%;
}

.mainbar-conversation-message-card__image,
.mainbar-conversation-message-card__video,
.mainbar-conversation-message-card__media,
.mainbar-conversation-message-card__story,
.mainbar-conversation-message-card__post {
   height: 150px;
   width: 150px;
   cursor: pointer;
}

.mainbar-conversation-message-card__story,
.mainbar-conversation-message-card__post {
   margin-top: 10px;
}

.mainbar-conversation-message-card__document {
   width: 20px;
   height: 20px;
   margin-right: 5px;
   filter: brightness(1.5);
}

.mainbar-conversation-empty-space-card-wrapper {
   background: #e7e9f1;
   border-radius: 18px 18px 18px 0px;
   font-size: 14px;
   padding: 26px;
   line-height: 21px;
   margin: 16px 0;
   float: left;
   overflow-wrap: break-word;
   color: #7f808c;
   position: relative;
   width: 60%;
   height: 100%;
}

.mainbar-chat-template-box {
   background-color: #fff;
   box-shadow: 0px 6px 26px rgba(73, 154, 228, 0.16);
   border-radius: 6px;
   position: absolute;
   bottom: 0;
   right: 175px;
   width: 270px;
   max-height: 204px;
   padding-top: 10px;
   padding-bottom: 10px;
   z-index: 10;
   overflow: auto;
}

.mainbar-chat-template-box::-webkit-scrollbar {
   display: block;
   width: 2px;
}

.mainbar-chat-template-box::-webkit-scrollbar-thumb {
   background-color: #40b7d9;
}

.mainbar-chat-template-box::-webkit-scrollbar-track {
   background-color: #f0f0f0;
}

.mainbar-chat-emoji-box {
   position: absolute;
   bottom: 21px;
   right: 45px;
   width: 375px;
   height: auto;
   padding: 13px;
   z-index: 999;
}

.mainbar-chat-template {
   background: #ffffff;
   width: 222px;
   padding: 6px 20px;
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   cursor: pointer;
   white-space: pre-wrap;
   overflow: hidden;
   text-overflow: ellipsis;
   border-radius: 0px 6px 6px 0px;
}

.mainbar-chat-template:hover {
   background: #def8ff;
}

.audio_player {
   max-width: 325px;
   width: 100%;
}

.delete-message-pop-up-wrap{
   border-radius: 6px;
   overflow: hidden;
   -moz-transition: opacity 0.3s linear;
   -o-transition: opacity 0.3s linear;
   -webkit-transition: opacity 0.3s linear;
   box-shadow: 0 6px 26px rgba(73, 154, 228, 0.16);
   z-index: 20;
}

.delete-message-pop-up-wrap:before{
   position: absolute;
   left: -50px;
   top: -50px;
   right: -50px;
   bottom: -50px;
   display: block;
   content: '';
   z-index: -1;
}

.delete-message-pop-up {
   background: #FFFFFF;
   max-width: 220px;
   height: 57px;
   padding: 0 10px;
   display: flex;
   align-items: center;
   cursor: pointer;
   font-weight: 500;
   font-size: 14px;
   line-height: 17px;
   font-family: 'Inter';
   -moz-transition: opacity 0.3s linear;
   -o-transition: opacity 0.3s linear;
   -webkit-transition: opacity 0.3s linear;
}

.delete-message-pop-up a {
   display: flex;
   align-items: center;
   font-weight: inherit;
   font-size: inherit;
   line-height: inherit;
   font-family: inherit;
   color: inherit;
   text-decoration: none;
}

.delete-message-pop-up:hover {
   background-color: #DEF8FF;
}

.delete-message-pop-up.cssAnimate:hover {
   background: #DEF8FF;
}

.delete-message-pop-up-icon {
   height: 17px;
   width: 17px;
   margin: 0 4px 0 0;
   pointer-events: none;
}

.reply-btn {
   position: absolute;
   cursor: pointer;
   top: 0px;
   right: -40px;
   width: 20px;
   height: 20px;
}

.reply-btn__tooltip {
   right: 0;
   transform: translate(calc(100% + 50px), -5px);
}

.reply-btn:hover ~ .reply-btn__tooltip {
   visibility: visible;
   opacity: 1;
}