.authLogo {
   position: absolute;
   width: 240px;
   top: 32px;
   left: 32px;
}

@media screen and (max-width: 575.99px) {
   .authLogo {
      top: 15px;
      left: 15px;
   }

   .authLogo {
      width: 160px;
   }
}