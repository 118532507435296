.dropdown-list-border {
   opacity: 0.2;
   margin-bottom: 20px;
   margin-top: 20px;
}

.dropdown-wrapper {
   position: relative;
}

.dropdown-current-item {
   background: #ffffff;
   box-shadow: 0px 6px 26px rgba(211, 223, 228, 0.34);
   border-radius: 6px;
   cursor: pointer;
   height: 40px;
   display: flex;
   flex-direction: row;
   padding-left: 20px;
   padding-right: 20px;
   align-items: center;
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
   justify-content: space-between;
}
.dropdown-list {
   width: 100%;
   position: absolute;
   z-index: 10;
   margin-top: 4px;
   display: flex;
   flex-direction: column;
   background: #ffffff;
   box-shadow: 0px 6px 26px rgba(211, 223, 228, 0.34);
   border-radius: 6px;
}

.dropdown-list_wrapper {
   padding: 20px 24px;
}

.dropdown-list_selected-items-block {
   height: 100px;
   overflow: auto;
   display: flex;
   flex-wrap: wrap;
   gap: 8px;
   align-content: flex-start;
}

.selected-item {
   height: 24px;
   list-style: none;
   background-color: #40B7D9;
   border-radius: 6px;
   padding: 4px 8px;
   display: flex;
   color: white;
}

.selected-item_block {
   display: flex;
   flex-direction: row;
   align-items: center;
   gap: 10px;
}

.dropdown-list_items {
   max-height: 115px;
   overflow: auto;
}

.dropdown-list_items::-webkit-scrollbar {
   display: block;
   width: 2px;

}

.dropdown-list_items::-webkit-scrollbar-thumb {
   background-color: #40B7D9;
}

.dropdown-list_item {
   list-style: none;
   margin: 0;
   cursor: pointer;
}

.dropdown-list_item:not(:last-child) {
   margin-bottom: 16px;
}

.dropdown-list_item:hover {
   color: lightblue;
}

.dropdown-list_item-checkbox {
   width: 18px;
   height: 18px;
   border: 1px solid #afafaf;
   border-radius: 6px;
}

.dropdown-list_item-checkbox-active {
   width: 18px;
   height: 18px;
   background-color: #40b7d9;
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.dropdown-list_item-block {
   display: flex;
   flex-direction: row;
   gap: 10px;
   align-items: center;
   cursor: pointer;
}

.dropdown-list-accept-button {
   width: 100%;
   height: 41px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #40b7d9;
   background: #ffffff;
   box-shadow: 0px 6px 26px rgba(211, 223, 228, 0.34);
   border-radius: 6px;
   border: none;
   font-weight: 600;
   font-size: 14px;
   line-height: 17px;
}
