.edit-dialog-name-form {
   min-width: 337px;
   margin-top: 30px;
   display: flex;
   flex-direction: column;
}

.edit-dialog-name-form__status {
   margin-bottom: 20px;
   color: #d94040;
}

.edit-dialog-name-form__body {
   margin-bottom: 30px;
}

.edit-dialog-name-form__control {
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.edit-dialog-name-form__label {
   font-weight: 400;
   font-size: 12px;
   line-height: 14.52px;
   color: rgba(37, 37, 37, 1);
}

.edit-dialog-name-form__label_error {
   color: #d94040;
}

.edit-dialog-name-form__control input {
   width: 100%;
   min-height: 44px;
   padding: 14px 20px;
   border: 1px solid rgba(228, 228, 228, 1);
   border-radius: 6px;
   font-weight: 400;
   font-size: 14px;
   line-height: 16.94px;
}

.edit-dialog-name-form__control input:focus {
   outline: none;
}

.edit-dialog-name-form__footer {
   display: flex;
   flex-direction: column;
}

.edit-dialog-name-form__btn {
   width: 100%;
   min-height: 48px;
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: rgba(64, 183, 217, 1);
   font-weight: 500;
   font-size: 16px;
   line-height: 19.36px;
   color: white;
   cursor: pointer;
}