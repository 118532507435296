.contact-info {
   overflow: auto;
}

.contact-info-loader {
   height: 100%;
}

.contact-info-wrapper {
   position: relative;
   padding: 30px;
}

@media screen and (max-width: 900px) {
   .contact-info-wrapper {
      padding: 20px 50px 30px;
   }
}

.contact-info-header {
   display: flex;
   flex-direction: column;
   gap: 30px;
}

.contact-info-header_wrapper {
   display: flex;
   flex-direction: row;
   gap: 18px;
}

.contact-info-header_block {
   display: flex;
   align-items: flex-start;
   flex-direction: column;
   gap: 7px;
}

.contact-info-header_avatar-icon {
   position: relative;
   right: 12px;
}

.contact-info-header_block-name {
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
}

.contact-info-header_block-go-to-dialogs.btn {
   padding: 0;
}

.contact-info-page-block {
   padding: 0 30px 0 30px;
   display: flex;
   flex-direction: row;
}

.contact-button-block {
   display: flex;
   gap: 30px;
   margin-bottom: 40px;
}

.contact-edit-form-button,
.mainbar-delete-manager-form-button,
.contact-archive-form-button {
   flex-direction: row-reverse;
}

.contact-archive-form-button svg {
   fill: rgba(237, 64, 60, 1);
}

.contact-archive-form-button:hover svg {
   fill: rgba(243, 101, 98, 1);
}

.contact-archive-form-button:active svg {
   fill: rgba(208, 42, 38, 1);
}

.contact-archive-form-button:disabled svg {
   fill: rgba(197, 199, 208, 1);
}
