.forward-messages-section {
   position: relative;
   padding: 19px 0 2px;
}

.forward-messages-section__body {
   display: flex;
   align-items: center;
   gap: 16px;
}

.forward-messages-section__btn {
   min-height: 42px;
   padding: 10px 18px;
   border: 1px solid rgba(64, 183, 217, 1);
   border-radius: 6px;
   display: flex;
   justify-content: center;
   align-items: center;
   font-weight: 400px;
   font-size: 14px;
   line-height: 18px;
   cursor: pointer;
   transition: all 0.3s ease;
}

.forward-messages-section__btn_forward {
   background-color: rgba(64, 183, 217, 1);
   color: white;
}

.forward-messages-section__btn_forward:hover {
   border-color: rgb(63, 171, 201);
   background-color: rgb(63, 171, 201);
}

.forward-messages-section__btn_delete {

   background-color: white;
   color: rgba(64, 183, 217, 1);
}

.forward-messages-section__btn_delete:hover {
   border-color: rgb(63, 171, 201);
   color: rgb(63, 171, 201);
}

.forward-messages-section__clear-btn {
   margin-left: auto;
   cursor: pointer;
}

.forward-messages-section-dialogs-list {
   position: absolute;
   bottom: 100%;
   left: 0;
   width: 314px;
   padding: 20px 20px 20px 0;
   border-radius: 6px;
   background-color: white;
   box-shadow: 0 6px 26px 0 rgba(73, 154, 228, 0.16);
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.forward-messages-section__dialogs-container.active .forward-messages-section-dialogs-list {
   visibility: visible;
   opacity: 1;
}

.forward-messages-section-dialogs-list__body {

}

.forward-messages-section-dialogs-list__list {
   list-style: none;
   max-height: 274px;
   padding-right: 28px;
   overflow: auto;
}

.forward-messages-section-dialogs-list__list::-webkit-scrollbar {
   display: block;
   width: 2px;
}

.forward-messages-section-dialogs-list__list::-webkit-scrollbar-thumb {
   background-color: #40B7D9;
}

.forward-messages-section-dialog-card {
   padding: 8px 20px;
   border-top-right-radius: 6px;
   border-bottom-right-radius: 6px;
   display: flex;
   align-items: center;
   gap: 12px;
   transition: all 0.3s ease;
   cursor: pointer;
}

.forward-messages-section-dialog-card:hover {
   background-color: rgba(222, 248, 255, 1);
}

.forward-messages-section-dialog-card__avatar {
   position: relative;
   width: 42px;
   height: 42px;
}

.forward-messages-section-dialog-card__avatar-photo {
   border-radius: 50%;
}

.forward-messages-section-dialog-card__channel-icon {
   position: absolute;
   right: 0;
   bottom: 0;
   width: 12px;
   height: 12px;
}