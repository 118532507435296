.drop-file-input-wrapper {
   flex: 1 1 auto;
   max-width: 100%;
   display: flex;
   flex-direction: column;
   gap: 8px;
}

.drop-file-input {
   padding: 20px;
   border: 1px dashed rgba(197, 199, 208, 1);
   border-radius: 12px;
   cursor: pointer;
}

.drop-file-input__body {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap: 8px;
}

.drop-file-input__desctiption {
   font-weight: 400;
   font-size: 12px;
   line-height: 16.8px;
}

.drop-file-input__desctiption img {
   margin: 0;
}

.drop-file-input__desctiption span {
   color: rgba(0, 172, 239, 1);
}

.drop-file-input__input {
   display: none;
}

.drop-file-input__files-list {
   list-style: none;
   display: flex;
   gap: 8px;
   overflow-x: auto;
}

.drop-file-input__files-list::-webkit-scrollbar {
   display: block;
   height: 4px;
}

.drop-file-input__files-list::-webkit-scrollbar-track,
.drop-file-input__files-list::-webkit-scrollbar-thumb {
   border-radius: 2px;
}

.drop-file-input__files-list-item {
   flex: 0 1 186px;
   min-width: 70px;
}