.form-tooltip {
   position: relative;
   width: 15px;
   height: 15px;
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid black;
   border-radius: 50%;
   font-size: 10px;
   color: black;
}

.form-tooltip:hover {
   cursor: pointer;
}

.form-tooltip-error {
   border-color: red;
   color: red;
}

.form-tooltip-body {
   position: absolute;
   top: -5px;
   right: 0;
   transform: translateY(-100%);
   width: 150px;
   padding: 5px 10px;
   border: 1px solid black;
   border-radius: 5px;
   background-color: rgba(255, 255, 255, 0.75);
   color: black;
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease;
}

.form-tooltip:hover .form-tooltip-body {
   opacity: 1;
   visibility: visible;
}