.notification-contact {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.2);
   z-index: 999;
}

.notification-contact-enter {
   opacity: 0;
}

.notification-contact-enter-active {
   opacity: 1;
   transition: opacity 500ms;
}

.notification-contact.exit {
   opacity: 1;
}

.notification-contact-exit-active {
   opacity: 0;
   transition: opacity 500ms;
}

.notification_wrapper {
   display: flex;
   flex-direction: column;
   position: fixed;
   background: #fff;
   width: 25%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 50px 50px;
   border-radius: 6px;
   box-shadow: 0px 6px 50px rgba(180, 185, 187, 0.6);
}

.notification_header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 18px;
}

.notification_text {
   margin-bottom: 30px;font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 140%;
   display: flex;
   align-items: center;
   text-align: center;

}

.notification_button-block {
   display: flex;
   justify-content: center;
}

.notification_button {
   flex: 1 1 auto;
}

.notification_button:hover {
   background-color: #4fc6e8;
}

.notification_button:active {
   background-color: #39b0d2;
}
