.manager-access-notificator {
   position: absolute;
   top: 0;
   left: 0;
   z-index: 999;
   width: 100%;
   height: 100%;
   padding-top: 200px;
   display: flex;
   flex-direction: column;
   align-items: center;
   pointer-events: none;
}

.manager-access-notificator__list {
   list-style: none;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: 12px;
}

.manager-access-notification {
   max-width: 470px;
   border-radius: 8px;
   display: flex;
   background-color: rgba(255, 255, 255, 1);
   box-shadow: 0 4px 20px 0 rgba(191, 191, 191, 0.4);
}

.manager-access-notification::before {
   flex: 0 0 8px;
   content: "";
   width: 8px;
   border-top-left-radius: 8px;
   border-bottom-left-radius: 8px;
   background-color: rgba(245, 139, 63, 1);
}

.manager-access-notification__body {
   pointer-events: all;
   padding: 12px 8px;
   display: flex;
   flex-direction: column;
   gap: 9px;
}

.manager-access-notification__header {
   display: flex;
   align-items: center;
   gap: 9px;
}

.manager-access-notification__icon {
   flex: 0 0 18px;
   margin: 0;
   pointer-events: none;
}

.manager-access-notification__title {
   font-weight: 400;
   font-size: 14px;
   line-height: 18px;
}

.manager-access-notification__close-btn {
   flex: 0 0 18px;
   margin-left: auto;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.manager-access-notification__close-btn img {
   pointer-events: none;
}

.manager-access-notification__text {
   font-weight: 400;
   font-size: 12px;
   line-height: 16.8px;
}