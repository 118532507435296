.navbar {
   position: absolute;
   width: 93px;
   background: #025d78;
   height: 100vh;
}

.navbar-logo-block {
   margin-bottom: 70px;
}

.navbar-wrapper {
   padding: 22px 16px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}

.navbar-view-bottom-button {
   display: flex;
   flex-direction: column;
   gap: 28px;
}

.navbar-view-toggle-buttons {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: #ffffffab;
   gap: 28px;
}

.navbar-header-logout-link {
   color: #fff;
   text-align: center;
   width: 100%;
   cursor: pointer;
}

.navbar-icon-section {
   width: 42px;
   height: 42px;
   border-radius: 50%;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
}

.navbar-icon-section:hover {
   background-color: lightcyan !important;
}

.navbar-icon-section img:hover {
   filter: invert(53%) sepia(47%) saturate(3253%) hue-rotate(162deg)
      brightness(97%) contrast(102%);
}

@media screen and (max-width: 575.98px) {
   .navbar {
      width: 60px;
   }

   .navbar-wrapper {
      padding: 22px 10px;
   }
}

.navlink {
   display: block;
   position: relative;
}

.navlink__tooltip {
   top: 50%;
   left: 100%;
   transform: translate(35px, -50%);
}

@media screen and (max-width: 575.98px) {
   .navlink__tooltip {
      transform: translate(25px, -50%);
   }
}

.navlink:hover .navlink__tooltip {
   visibility: visible;
   opacity: 1;
}