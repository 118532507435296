.select {
   position: relative;
   display: flex;flex-direction: column;
   gap: 4px;
}

.select__label {
   font-weight: 400;
   font-size: 12px;
   line-height: 16.8px;
}

.select__btn {
   width: 100%;
   min-height: 40px;
   padding: 4px 12px;
   border-radius: 8px;
   box-shadow: 0 6px 26px 0 rgba(211, 223, 228, 0.34);
   cursor: pointer;
}

.select__value {
   display: flex;
   align-items: center;
   gap: 4px;
   background-color: transparent;
   font-size: 14px;
   line-height: 16.94px;
   color: rgba(140, 148, 151, 1);
}

.select__value-list {
   display: flex;
   flex-wrap: nowrap;
   overflow: hidden;
   gap: 4px;
}

.select.active .select__value-list {
   flex-wrap: wrap;
}

.select__value-item {
   max-width: 90px;
   padding: 4px 12px;
   border-radius: 44px;
   display: flex;
   flex-wrap: nowrap;
   align-items: center;
   gap: 4px;
   background-color: rgba(235, 235, 235, 1);
   font-size: 14px;
   line-height: 19.6px;
   color: rgba(37, 37, 37, 1);
   cursor: default;
}

.select__value-item-text {
   text-wrap: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.select__value-item-delete-btn {
   flex: 0 0 14px;
   display: flex;
   align-items: center;
   cursor: pointer;
}

.select__value-item-delete-btn img {
   width: 14px;
   height: 14px;
}

.select__dropdown-icon {
   width: 11px;
   margin: 0;
   margin-left: auto;
   transform: rotate(180deg);
}

.select.active .select__dropdown-icon {
   margin-top: 5px;
   margin-bottom: auto;
   transform: rotate(0);
}

.select__list-wrapper {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   width: 100%;
   padding: 4px 12px;
   border-radius: 8px;
   background-color: white;
   box-shadow: 0 6px 26px 0 rgba(211, 223, 228, 0.34);
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.select.active .select__list-wrapper {
   visibility: visible;
   opacity: 1;
}

.select__list {
   list-style: none;
   max-height: 144px;
   overflow: auto;
}

.select__list::-webkit-scrollbar {
   display: block;
   width: 4px;
}

.select__list::-webkit-scrollbar-track {
   display: block;
   width: 4px;
   border-radius: 2px;
   background-color: rgba(16, 24, 40, 0.1);
}

.select__list::-webkit-scrollbar-thumb {
   height: 42px;
   border-radius: 2px;
   background-color: rgba(64, 183, 217, 1);
}

.select__list-item {
   padding: 8px 0;
   display: flex;
   align-items: center;
   gap: 8px;
   cursor: pointer;
}

.select__list-item-checkbox {
   flex: 0 0 20px;
   width: 20px;
   height: 20px;
   border: 1px solid rgba(64, 183, 217, 1);
   border-radius: 4px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.select__list-item-text {
   text-wrap: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}

.select__list-item_selected .select__list-item-checkbox {
   background-color: rgba(64, 183, 217, 1);
}