.messenger {
   height: 100vh;
   width: 100%;
}

.messenger-wrapper-active {
   height: 100%;
   display: grid;
   grid-template-columns: 493px calc(100% - 493px);
}

.messenger-wrapper-inactive {
   height: 100%;
   display: grid;
   grid-column-gap: 64px;
   grid-template-columns: 0px 100%;
}

.messenger-wrapper-tablet {
   display: grid;
   grid-template-columns: 100%;
}

.messenger-welcome {
   height: 100vh;
   width: 100%;
}

.messenger-welcome-wrapper {
   height: 100%;
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   line-height: 1.2;
   font-size: 15px;
}

.messenger-welcome-text {
   font-weight: 700;
   color: #242939;
   margin-bottom: 0.5rem;
}

.messenger-welcome-instructrions-text {
   color: #91929c;
}
