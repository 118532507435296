.status-item {
   list-style: none;
   display: flex;
}

/*.status-item:nth-child(n + 11) {*/
/*   justify-content: flex-end;*/
/*}*/

.status-item_block {
   display: flex;
   align-items: center;
   gap: 14px;
   padding: 8px 22px;
   border-radius: 6px;
   background: #edf7fa;
   color: #ffffff;
}

.status-item_title {
   font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 17px;
}

.status-item_icon {
   cursor: pointer;
}

@media (max-width: 900px) {
   .status-item_title {
      font-size: 16px;
      line-height: 19px;
   }
}
