.btn {
   flex: 0 0 auto;
   border-radius: 8px;
   border: 1px solid transparent;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 8px;
   font-size: 14px;
   line-height: 18px;
   color: white;
   cursor: pointer;
   transition: all 0.3s ease;
}

.btn__icon {
   transform: rotate(180deg);
}

.btn_primary {
   border-color: rgba(0, 172, 239, 1);
   background-color: rgba(0, 172, 239, 1);
   box-shadow: 0 6px 20px 0 rgba(108, 197, 222, 0.2);
}

.btn_primary:hover {
   border-color: rgba(54, 194, 249, 1);
   background-color: rgba(54, 194, 249, 1);
}

.btn_primary:active {
   border-color: rgba(21, 167, 224, 1);
   background-color: rgba(21, 167, 224, 1);
}

.btn_primary-skinny {
   border-color: rgba(0, 172, 239, 1);
   background-color: transparent;
   box-shadow: 0 6px 20px 0 rgba(108, 197, 222, 0.2);
   color: rgba(0, 172, 239, 1);
}

.btn_primary-skinny:hover {
   border-color: rgba(54, 194, 249, 1);
   background-color: transparent;
   color: rgba(54, 194, 249, 1);
}

.btn_primary-skinny:active {
   border-color: rgba(21, 167, 224, 1);
   background-color: transparent;
   color: rgba(21, 167, 224, 1);
}

.btn_danger {
   border-color: rgba(237, 64, 60, 1);
   background-color: rgba(237, 64, 60, 1);
   box-shadow: 0 6px 20px 0 rgba(108, 197, 222, 0.2);
}

.btn_danger:hover {
   border-color: rgba(243, 101, 98, 1);
   background-color: rgba(243, 101, 98, 1);
}

.btn_danger:active {
   border-color: rgba(208, 42, 38, 1);
   background-color: rgba(208, 42, 38, 1);
}

.btn_danger-skinny {
   border-color: rgba(237, 64, 60, 1);
   background-color: transparent;
   box-shadow: 0 6px 20px 0 rgba(108, 197, 222, 0.2);
   color: rgba(237, 64, 60, 1);
}

.btn_danger-skinny:hover {
   border-color: rgba(243, 101, 98, 1);
   background-color: transparent;
   color: rgba(243, 101, 98, 1);
}

.btn_danger-skinny:active {
   border-color: rgba(208, 42, 38, 1);
   background-color: transparent;
   color: rgba(208, 42, 38, 1);
}

.btn_link {
   color: rgba(0, 172, 239, 1);
}

.btn_link:hover {
   color: rgba(54, 194, 249, 1);
}

.btn_link:active {
   color: rgba(21, 167, 224, 1);
}

.btn_primary:disabled,
.btn_danger:disabled {
   border-color: rgba(197, 199, 208, 1);
   background-color: rgba(197, 199, 208, 1);
}

.btn_link:disabled {
   color: rgba(197, 199, 208, 1);
}

.btn_primary .btn__icon path,
.btn_danger .btn__icon path {
   fill: white;
}

.btn_default {
   min-height: 42px;
   padding: 10px 18px;
   font-weight: 500;
}

.btn_link.btn_default {
   min-height: auto;
   padding: 12px 0;
} 

.btn_small {
   min-height: 34px;
   padding: 6px 10px;
   font-weight: 400;
}

.btn_link.btn_small {
   min-height: auto;
   padding: 8px 0;
} 

.btn_icon-left {
   flex-direction: row-reverse;
}