.contact-date-picker {
   flex: 0 1 216px;
   max-width: 216px;
   position: relative;
   display: flex;
   flex-direction: column;
}

.contact-date-picker-button {
   display: flex;
   flex-direction: column;
   gap: 4px;
}

.contact-date-picker-button__label {
   font-weight: 400;
   font-size: 10px;
   line-height: 13px;
   color: rgba(140, 148, 151, 1);
}

.contact-date-picker-button__btn {
   min-height: 29px;
   padding: 4px 8px;
   display: flex;
   align-items: center;
   gap: 4px;
   border-radius: 8px;
   background-color: white;
   cursor: pointer;
}

.contact-date-picker-button__value {
   flex: 1 1 auto;
   min-width: 76px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   gap: 4px;
   font-weight: 400;
   font-size: 12px;
   line-height: 14.52px;
   color: rgba(21, 140, 174, 1);
}

.contact-date-picker-button__btn-icon {
   margin: 0;
   transition: all 0.3s ease;
}

.contact-date-picker.active .contact-date-picker-button__btn-icon {
   transform: rotate(180deg);
}

.contact-date-picker-button__date-status {
   min-width: 102px;
   min-height: 21px;
   padding: 2px 8px;
   border-radius: 4px;
   text-align: center;
   font-weight: 400;
   font-size: 12px;
   line-height: 16.8px;
}

.contact-date-picker-button__date-status_outdated {
   background-color: rgba(255, 204, 204, 1);
   color: rgba(237, 86, 86, 1);
}

.contact-date-picker-button__date-status_today {
   background-color: rgba(255, 227, 206, 1);
   color: rgba(245, 139, 63, 1);
}

.contact-date-picker-button__date-status_future {
   background-color: rgba(200, 255, 202, 1);
   color: rgba(67, 219, 74, 1);
}

.contact-date-picker__picker-wrapper {
   display: block;
   height: 0;
}

.contact-date-picker__popup {
   position: absolute;
   z-index: 10;
   top: calc(100% + 4px);
   left: 0;
   padding: 24px 16px;
   display: flex;
   flex-direction: column;
   border-radius: 12px;
   background-color: white;
   box-shadow: 0px 6px 50px 0 rgba(180, 185, 187, 0.6);
   visibility: hidden;
   opacity: 0;
   transition: all 0.3s ease;
}

.contact-date-picker.active .contact-date-picker__popup {
   visibility: visible;
   opacity: 1;
}

.contact-date-picker__picker {
   position: static !important;
   transform: none !important;
}

.contact-date-picker__picker[data-placement^=bottom] {
   padding-top: 0;
}

.contact-date-picker.active .contact-date-picker__picker {
   visibility: visible;
   opacity: 1;
}

.contact-date-picker__input {
   display: none;
}

.contact-date-picker__calendar.react-datepicker {
   margin-bottom: 12px;
   border: none;
   border-radius: 12px;
   background-color: white;
}

.contact-date-picker__calendar .react-datepicker__triangle {
   display: none;
}

.contact-date-picker__calendar .react-datepicker__navigation {
   top: -3px;
}

.contact-date-picker__calendar .react-datepicker__navigation--previous {
   left: auto;
   right: 26px;
}

.contact-date-picker__calendar .react-datepicker__navigation--next {
   right: -16px;
}

.contact-date-picker__calendar .react-datepicker__navigation-icon::before {
   width: 6px;
   height: 6px;
   border-width: 1px 1px 0 0;
   border-color: rgba(140, 148, 151, 1);
}

.contact-date-picker__calendar .react-datepicker__month-container {
   display: flex;
   flex-direction: column;
   float: none;
}

.contact-date-picker__calendar .react-datepicker__header {
   padding: 0;
   border: none;
   background-color: transparent;
}

.contact-date-picker__calendar .react-datepicker__current-month {
   padding-bottom: 12px;
   border-bottom: 1px solid rgba(228, 229, 231, 1);
   font-weight: 500;
   font-size: 14px;
   line-height: 16.94px;
   text-align: left;
}

.contact-date-picker__calendar .react-datepicker__current-month::first-letter {
   text-transform: uppercase;
}

.contact-date-picker__calendar .react-datepicker__day-names {
   margin-top: 12px;
   margin-bottom: 0;
   display: flex;
   justify-content: space-between;
}

.contact-date-picker__calendar .react-datepicker__day-name,
.contact-date-picker__calendar .react-datepicker__day {
   flex: 0 0 24px;
   width: 24px;
   height: 24px;
   margin: 0;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 14px;
   line-height: 14px;
}

.contact-date-picker__calendar .react-datepicker__day-name {
   height: 20px;
}

.contact-date-picker__calendar .react-datepicker__day:hover {
   border-radius: 50%;
}

.contact-date-picker__calendar .react-datepicker__day--selected {
   border-radius: 50%;
   background-color: rgba(37, 128, 155, 1);
   color: white;
}
.contact-date-picker__calendar .react-datepicker__day--keyboard-selected {
   background: none;
}

.contact-date-picker__calendar .react-datepicker__day--keyboard-selected:hover {
   background-color: #f0f0f0;
}

.contact-date-picker__calendar .react-datepicker__day-names .react-datepicker__day-name {
   font-size: 12px;
   color: rgba(126, 129, 140, 1);
}

.contact-date-picker__calendar .react-datepicker__month {
   margin: 16px 0 0;
   display: flex;
   flex-direction: column;
   gap: 16px;
}

.contact-date-picker__calendar .react-datepicker__week {
   display: flex;
   justify-content: space-between;
   gap: 12px;
}

.contact-date-picker__delete-date-btn {
   min-height: 36px;
   padding: 8px 26px;
   display: flex;
   justify-content: center;
   border-radius: 6px;
   background-color: rgba(64, 183, 217, 1);
   box-shadow: 0 6px 20px 0 rgba(108, 197, 222, 0.2);
   font-size: 14px;
   line-height: 19.6px;
   color: white;
   cursor: pointer;
}

.contact-date-picker__delete-date-btn:disabled {
   background-color: rgba(184, 197, 201, 1);
   cursor: default;
}