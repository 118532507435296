.tooltip {
   position: absolute;
   z-index: 110;
   visibility: hidden;
   opacity: 0;
   pointer-events: none;
   transition: all 0.3s ease;
}

.tooltip__body {
   position: relative;
   padding: 8px 10px;
   border-radius: 7px;
   background-color: rgba(2, 93, 120, 0.8);
   color: white;
   font-size: 14px;
   font-weight: 400;
   line-height: 16px;
   white-space: nowrap;
}

.tooltip__body::before {
   content: "";
   display: block;
   width: 0;
   height: 0;
   width: 0;
   height: 0;
   position: absolute;
   z-index: -10;
}

.tooltip_top .tooltip__body::before {
   bottom: 0;
   left: 50%;
   transform: translate(-50%, 100%);
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-top: 6px solid rgba(2, 93, 120, 0.8);
}

.tooltip_right .tooltip__body::before {
   top: 50%;
   left: 0;
   transform: translate(-100%, -50%);
   border-top: 5px solid transparent;
   border-right: 6px solid rgba(2, 93, 120, 0.8);
   border-bottom: 5px solid transparent;
}

.tooltip_bottom .tooltip__body::before {
   top: 0;
   left: 50%;
   transform: translate(-50%, -100%);
   border-left: 5px solid transparent;
   border-right: 5px solid transparent;
   border-bottom: 6px solid rgba(2, 93, 120, 0.8);
}

.tooltip_left .tooltip__body::before {
   top: 50%;
   right: 0;
   transform: translate(100%, -50%);
   border-top: 5px solid transparent;
   border-left: 6px solid rgba(2, 93, 120, 0.8);
   border-bottom: 5px solid transparent;
}