.reply-message {
   border-radius: 4px;
   display: flex;
   gap: 8px;
   align-items: center;
   background-color: rgba(127, 209, 232, 1);
   overflow: hidden;
   cursor: pointer;
}

.mainbar-conversation-input-message-card .reply-message {
   background-color: rgba(189, 235, 248, 1);
}

.reply-message:not(:last-child) {
   margin-bottom: 8px;
}

.reply-message::before {
   content: "";
   width: 4px;
   height: 50px;
   flex: 0 0 auto;
   background-color: #158CAE;
}

.mainbar-conversation-input-message-card .reply-message::before {
   background-color: rgba(57, 176, 210, 1);
}

.reply-message__image {
   width: 40px;
   height: 40px;
   border-radius: 5px;
   flex: 0 0 auto;
   overflow: hidden;
   position: relative;
}

.reply-message__image img {
   position: absolute;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   object-fit: cover;
}

.reply-message__info {
   flex: 1 1 auto;
   display: flex;
   flex-direction: column;
   gap: 2px;
   font-size: 14px;
   overflow: hidden;
}

.reply-message__title {
   font-weight: 500;
   line-height: 16.94px;
   color: #158CAE;
}

.mainbar-conversation-input-message-card .reply-message__title {
   color: rgba(57, 176, 210, 1);
}


.reply-message__text {
   line-height: 19.04px;
   color: rgba(37, 37, 37, 1);
   white-space: nowrap;
   text-overflow: ellipsis;
   overflow: hidden;
}