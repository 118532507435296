.previewer__body {
   display: flex;
   flex-direction: column;
}

.previewer-close-btn {
   display: flex;
   justify-content: flex-end;
}

.previewer__image {
   position: relative;
   flex: 1 1 auto;
}

.previewer__image img {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   object-fit: contain;
}