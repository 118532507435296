.basic {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.2);
   z-index: 999;
}

.basic-close-button {
   background-color: transparent;
   border-radius: 32px;
   color: white;
   border: none;
   cursor: pointer;
}

.basic-close {
   width: 20px;
   height: 20px;
}

.basic-enter {
   opacity: 0;
}

.basic-enter-active {
   opacity: 1;
   transition: opacity 500ms;
}

.basic.exit {
   opacity: 1;
}

.basic-exit-active {
   opacity: 0;
   transition: opacity 500ms;
}

.basic_wrapper {
   position: fixed;
   background: #fff;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   padding: 50px 50px;
   border-radius: 6px;
   box-shadow: 0px 6px 50px rgba(180, 185, 187, 0.6);
}

.basic_header {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 18px;
}
.basic_title {
   text-align: center;
   font-style: normal;
   font-weight: 600;
   font-size: 22px;
   line-height: 136%;
}

.basic_text {
   max-width: 390px;
   font-style: normal;
   font-weight: 400;
   font-size: 16px;
   line-height: 140%;
   text-align: center;
   margin-bottom: 30px;
}

.basic_buttons {
   display: flex;
   justify-content: center;
}

.basic_button {
   width: 0.1px;
   height: 0.1px;
   opacity: 0;
   overflow: hidden;
   position: absolute;
   z-index: -1;
}

.basic_button-label {
   min-height: 42px;
   padding: 10px 18px;
   border: 1px solid rgba(0, 172, 239, 1);
   border-radius: 8px;
   background-color: rgba(0, 172, 239, 1);
   cursor: pointer;
   font-weight: 500;
   font-size: 14px;
   line-height: 18px;
   color: white;
}

.basic_button-label:hover {
   border-color: rgba(54, 194, 249, 1);
   background-color: rgba(54, 194, 249, 1);
}

.basic_button-label:active {
   border-color: rgba(21, 167, 224, 1);
   background-color: rgba(21, 167, 224, 1);
}

.yes {
   background: #40b7d9;
   color: #ffffff;
   border: none;
}

.yes:hover {
   background-color: #4fc6e8;
}

.yes:active {
   background-color: #39b0d2;
}

.no {
   border: 1px solid #40b7d9;
   background: #ffffff;
   color: #40b7d9;
}

.no:hover {
   background-color: #4fc6e8;
   color: #ffffff;
}

.no:active {
   background-color: #39b0d2;
}
@media (max-width: 900px) {
   .basic_wrapper {
      width: 85% !important;
      padding: 30px 18px;
   }

   .basic_title {
      font-size: 20px;
      line-height: 138%;
      text-align: center;
   }

   .basic_text {
      font-size: 14px;
      line-height: 140%;
   }
}
